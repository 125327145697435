import React from 'react';
import { Box, Text, Image, SimpleGrid } from '@chakra-ui/react';
import aiConsultingIcon from '../../assets/images/aiconsulting.png';  // Example image
import mlModelsIcon from '../../assets/images/mlmodelimage.png';      // Example image
import automationIcon from '../../assets/images/aipowerimage.png';    // Example image
import nlpIcon from '../../assets/images/nlpimage.png';               // Example image
import SectionTitle from '../../components/SectionTitle';

const services = [
  {
    title: 'Real-Time Data Analytics',
    image: aiConsultingIcon,
  },
  {
    title: 'Seamless Data Integration',
    image: mlModelsIcon,
  },
  {
    title: 'User-Driven Reporting Tools',
    image: automationIcon,
  },
  {
    title: 'Compliance and Security Solutions',
    image: nlpIcon,
  },
];

const ServicesSection = () => {
  return (
    <Box
      width="100%"
      padding={{ base: '20px', md: '50px', lg: '100px' }}
      background="transparent"
      position="relative"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      {/* Section Heading */}
      <SectionTitle text="Our Services" position="center" />

      {/* Grid of Service Cards */}
      <SimpleGrid
        columns={{ base: 1, sm: 2, md: 2, lg: 4 }}
        spacing={{ base: 5, sm: 6, md: 8, lg: 10 }}
        width="100%"
        maxW="1200px"
        mt={{ base: 10, md: 16 }}
      >
        {services.map((service, index) => (
          <Box
            key={index}
            bg="rgba(128, 128, 128, 0.2)" // Slightly grayish transparent background
            borderRadius="20px"
            p={{ base: 5, sm: 6, md: 5 }} // Increased padding
            width="100%"
            maxW={{ base: '100%', sm: '100%', md: '100%', lg: '500px' }} // Increased maxW for lg screens
            boxShadow="0 10px 30px rgba(0, 0, 0, 0.5)"
            transition="transform 0.3s ease, box-shadow 0.3s ease"
            _hover={{
              transform: 'scale(1.05)',
              boxShadow: '0 20px 40px rgba(0, 0, 0, 0.4)',
            }}
            mx="auto"
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            border="1px solid rgba(255, 255, 255, 0.2)" // Subtle border for enhanced visibility
          >
            {/* Image without circular container */}
            <Box mb={{ base: 4, sm: 5 }} display="flex" justifyContent="center">
              <Image
                src={service.image}
                alt={service.title}
                objectFit="contain"
                maxH={{ base: '110px', sm: '130px', md: '160px' }} // Increased maxH for images
                w="auto"
              />
            </Box>

            {/* Service Title */}
            <Text
              fontSize={{ base: 'md', sm: 'lg', md: '2xl' }} // Slightly larger font size
              fontWeight="bold"
              color="white"
              mt={3}
              noOfLines={2} // Limits to 2 lines and adds ellipsis if overflow
              textShadow="0 0 5px rgba(0, 0, 0, 0.7)" // Adds text shadow for better readability
            >
              {service.title}
            </Text>

            {/* Optional: Add a brief description or button */}
            {/* <Text fontSize="sm" color="gray.300" mt={2}>
              Brief description of the service.
            </Text>
            <Button
              mt={4}
              variant="outline"
              borderColor="white"
              color="white"
              size="sm"
              _hover={{ bg: 'whiteAlpha.200' }}
            >
              Learn More
            </Button> */}
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default ServicesSection;
