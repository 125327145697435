import { Box, Text, Button, Stack, Image } from '@chakra-ui/react';
import image from '../../assets/images/aipowerimage.png';
import starImage from '../../assets/images/image.png';  // Import the star image

const AIPoweredAutomation = () => (
  <Box
    width="100vw"
    height="auto"
    display="flex"
    alignItems="center"
    padding="50px"
    background="transparent"
    flexDirection={{ base: 'column', md: 'row' }} // Stack vertically on small screens
    position="relative" // Make the container relative for absolute positioning inside
    m={0}
  >
    {/* Rotating Star in the top-left corner */}
    <Box
      as="img"
      src={starImage}
      alt="Rotating Star"
      position="absolute"
      top={{base: '10px', md: '60px'}}
      left={{base: '10px', md: '50px'}}
      width="30px"  // Adjust the size as needed
      height="30px"
      animation="rotate 5s linear infinite"  // Rotating animation
    />

    {/* Container for the Image */}
    <Box
      width={{ base: '100%', md: '45%' }} // Full width on small screens, 45% on medium and up
      height="auto" // Adjust height automatically
      overflow="hidden" // Ensures no overflow of the image
      borderRadius="md" // Optional: rounded corners
    >
      <Image 
        src={image}
        alt="Background" 
        height={{ base: '30%', md: '90%' }} // Adjust height based on screen size
        width="80%" // Full width of the container
        objectFit="cover" // Ensures the image covers the box without distortion
      />
    </Box>

    {/* Container for the Text */}
    <Stack 
      textAlign="left" 
      spacing={2} 
      zIndex={2} 
      width={{ base: '100%', md: '55%' }} // Full width on small screens, 55% on medium and up
      paddingLeft={{ base: '0', md: '50px' }} // No padding on small screens, padding on larger ones
      mt={{ base: '20px', md: '0' }} // Margin top on small screens for separation
    >
      <Text fontSize={{ base: '3xl', md: '6xl' }} fontWeight="bold" color="white" fontFamily="CabinetGrotesk-Black">
      User-Driven Reporting Tools
      </Text>
      <Text fontSize={{ base: 'md', md: 'l' }} color="white" fontFamily="CabinetGrotesk-Regular">
      Empower your business users to create their own reports and analytics without needing IT support. This self-service capability fosters a culture of data-driven decision-making across your organization.      </Text>
      <Button
        mt={5}
        variant="outline"
        borderColor="white"
        color="white"
        width={{ base: '100%', md: 'auto' }} // Full width on small screens, auto on larger ones
        maxWidth={400}
        minHeight={50}
        borderRadius={20}
        _hover={{ bg: 'whiteAlpha.200' }}
      >
        Learn More About Our Journey
      </Button>
    </Stack>

    {/* Rotating Star Animation */}
    <style>
      {`
        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      `}
    </style>
  </Box>
);

export default AIPoweredAutomation;
